import React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

const IndexPage = () => {
  const Button = ({ text }) => {
    return (
      <div className="space-y-4 sm:space-y-0   ">
        <Link
          to={"/poptavka-fotovoltaika/"}
          className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-lime-600 bg-opacity-80 hover:bg-opacity-60 sm:px-8"
        >
          {text ? text : "Získejte nabídku"}
        </Link>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fotovoltaika | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative md:py-5">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <div className="max-w-7xl mx-auto  ">
            <div className="relative shadow-xl sm:rounded-lg sm:overflow-hidden">
              <div className="absolute inset-0">
                <StaticImage
                  src="../images/panely-strecha.jpg"
                  className="h-full w-full object-cover border border-gray-200 sm:rounded-lg"
                  alt="Malgen"
                />
                <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
                <div className="absolute inset-0 bg-gradient-to-r from-sky-800 to-transparent " />
              </div>
              <div className="relative px-8 py-12 lg:px-14">
                <h1 className=" text-3xl  md:text-4xl grid ">
                  <span className=" text-white leading-8">
                    Vlastní energie ze slunečního svitu je tady
                  </span>
                  <span className=" text-white font-bold text-xl md:text-2xl mt-2">
                    Energie budoucnosti
                  </span>
                </h1>
                <div className="text-sky-100 max-w-lg mt-5 pr-5">
                  Naše fotovoltaické systémy jsou navrženy v základních 4
                  produktech, které lze upravit na přání zákazníka. Systémy jsou
                  navrženy i tak, aby zákazníkovi umožnily čerpání dotace v
                  maximální výši.
                </div>
                <div className="mt-10 flex ">
                  <Button />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-200 pt-12 sm:pt-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center  ">
              <h2 className="text-2xl inset-0 sm:text-4xl">
                <strong>Benefity</strong> fotovoltaiky SOMI
              </h2>
            </div>
          </div>
          <div className="mt-10 pb-12 bg-white sm:pb-16">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 bg-gray-200" />
              <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto ">
                  <div className="rounded-lg  grid gap-4 p-10  md:grid-cols-4 bg-white shadow-md overflow-hidden">
                    <div className="relative">
                      <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                      <div className="grid grid-cols-4 relative">
                        <div>
                          <div className="bg-sky-600 rounded-full p-3 inline-block ">
                            <svg
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-8 h-8 text-white mx-auto"
                              viewBox="0 0 297 297"
                              fill="currentColor"
                            >
                              <path
                                d="M256.907,10.171H60.73c-10.266,0-20.092,3.971-27.516,10.995c-7.904-2.103-14.14-3.581-17.31-4.308
                              c-4.079-0.935-8.354,0.15-11.495,2.914c-3.14,2.764-4.757,6.87-4.347,11.033c1.148,11.617,6.976,64.308,20.569,91.433v124.464
                              c0,22.126,17.987,40.127,40.098,40.127h196.177c22.107,0,40.094-18.001,40.094-40.127V50.298
                              C297,28.172,279.014,10.171,256.907,10.171z M100.69,144.238l4.486,4.462c0.108,9.789,8.065,17.702,17.875,17.702
                              c9.875,0,17.879-8.018,17.879-17.902c0-9.606-7.56-17.42-17.044-17.86l-4.138-4.115c5.584-8.247,8.919-17.823,9.6-27.996
                              c0.451-6.751-0.286-13.397-2.129-19.714c9.863-4.481,20.672-6.838,31.595-6.838c42.139,0,76.42,34.328,76.42,76.523
                              c0,42.192-34.281,76.52-76.42,76.52c-41.29,0-75.012-32.967-76.352-73.991C88.879,149.788,95.042,147.497,100.69,144.238z
                              M103.407,96.793c-0.253,3.78-1.188,7.411-2.727,10.769L78.565,85.569c-5.09-5.063-13.322-5.041-18.384,0.051
                              c-5.063,5.09-5.04,13.321,0.051,18.384l20.757,20.642c-3.126,0.964-6.407,1.47-9.741,1.47c-9.421,0-18.368-3.975-24.549-10.905
                              c-7.632-8.56-14.581-40.732-18.514-68.457c27.67,7.526,59.62,18.652,67.339,27.301C101.165,80.377,103.964,88.452,103.407,96.793z
                              M271.001,246.702c0,7.791-6.323,14.129-14.095,14.129H60.73c-7.774,0-14.099-6.338-14.099-14.129v-99.953
                              c3.147,1.439,6.422,2.6,9.789,3.47c0.921,55.739,46.496,100.8,102.394,100.8c56.474,0,102.419-45.989,102.419-102.519
                              c0-56.531-45.945-102.523-102.419-102.523c-15.411,0-30.665,3.504-44.46,10.157c-6.94-7.359-20.072-14.151-34.622-19.965h177.175
                              c7.771,0,14.095,6.337,14.095,14.128V246.702z"
                              />
                              <path
                                d="M194.581,166.402c9.875,0,17.89-8.018,17.89-17.902c0-9.89-8.015-17.902-17.89-17.902c-9.874,0-17.882,8.013-17.882,17.902
                              C176.699,158.384,184.707,166.402,194.581,166.402z"
                              />
                            </svg>
                          </div>
                        </div>

                        <span className="   text-gray-900 col-span-3 mt-10">
                          Prodej přebytků zpět do sítě nebo uložení energie na
                          pozdější využití
                        </span>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                      <div className="grid grid-cols-4 relative">
                        <div>
                          <div className="bg-sky-600 rounded-full p-3 inline-block ">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 297 297"
                              className="text-white h-8 w-8 mx-auto "
                              fill="currentColor"
                            >
                              <path d="m293.697,128.584l-138.368-124.672c-3.797-3.42-9.565-3.424-13.366-0.008l-138.652,124.672c-3.076,2.766-4.13,7.143-2.647,11.006 1.48,3.861 5.189,6.412 9.327,6.412h31.38v139.672c0,5.518 4.473,9.99 9.989,9.99h194.281c5.517,0 9.989-4.473 9.989-9.99v-139.672h31.38c4.136,0 7.843-2.549 9.325-6.408 1.484-3.86 0.435-8.235-2.638-11.002zm-168.173,57.57h45.953v6.494c0,12.668-10.307,22.975-22.977,22.975s-22.977-10.307-22.977-22.975v-6.494zm110.128,89.521h-77.192c0.014-0.203 0.031-0.406 0.031-0.613v-40.645c18.883-4.516 32.967-21.525 32.967-41.77v-6.494h6.493c5.517,0 9.989-4.473 9.989-9.99 0-5.516-4.473-9.99-9.989-9.99h-14.735v-22.975c0-5.518-4.473-9.99-9.989-9.99s-9.989,4.473-9.989,9.99v22.975h-29.471v-22.975c0-5.518-4.473-9.99-9.989-9.99-5.517,0-9.989,4.473-9.989,9.99v22.975h-14.737c-5.517,0-9.989,4.475-9.989,9.99 0,5.518 4.473,9.99 9.989,9.99h6.493v6.494c0,20.244 14.084,37.254 32.967,41.77v40.645c0,0.207 0.018,0.41 0.031,0.613h-77.192v-135.193l87.152-77.676 87.148,77.676v135.193zm13.795-149.66l-94.297-84.047c-3.787-3.377-9.506-3.377-13.293-0.002l-94.301,84.049h-11.512l112.594-101.242 112.363,101.242h-11.554z" />
                            </svg>
                          </div>
                        </div>

                        <span className="   text-gray-900 col-span-3 mt-10">
                          Práce v sítí i v ostrovním módu
                        </span>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                      <div className="grid grid-cols-4 relative">
                        <div>
                          <div className="bg-sky-600 rounded-full p-3 inline-block ">
                            <svg
                              fill="currentColor"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-white h-8 w-8 mx-auto "
                              viewBox="0 0 456.855 456.855"
                            >
                              <path
                                d="M456.855,72.488L290.673,0h-124.49L0,72.488v62.348h149.276l-85.707,292.02H0.928v30h455v-30h-62.641l-85.707-292.02
	h149.276V72.488z M184.312,30h88.232v74.836h-88.232V30z M180.542,134.836h95.772l14.531,49.508l-62.417,67.988l-62.417-67.988
	L180.542,134.836z M30,104.836V92.133l124.312-54.225v66.928H30z M156.145,217.958l51.92,56.554L107.347,384.221L156.145,217.958z
	 M108.933,426.855l119.495-130.162l119.495,130.162H108.933z M349.508,384.221L248.791,274.513l51.919-56.554L349.508,384.221z
	 M426.855,104.836H302.544V37.908l124.312,54.225V104.836z"
                              />
                            </svg>
                          </div>
                        </div>

                        <span className="   text-gray-900 col-span-3 mt-10">
                          Využití sítě nebo solární energie pro nabití bateriové
                          banky
                        </span>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                      <div className="grid grid-cols-4 relative">
                        <div>
                          <div className="bg-sky-600 rounded-full p-3 inline-block ">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-8 h-8 text-white mx-auto"
                              viewBox="0 0 512 512"
                              fill="currentColor"
                            >
                              <path
                                d="M511.936,250.128c-1.298-57.879-22.545-114.227-59.83-158.66C408.154,39.088,346.436,6.959,278.319,1
                              c-68.12-5.96-134.476,14.962-186.855,58.916c-52.38,43.952-84.509,105.67-90.468,173.786
                              c-5.96,68.117,14.963,134.477,58.916,186.855l31.799-26.682C54.886,349.988,37.356,294.39,42.35,237.319
                              c4.994-57.071,31.911-108.78,75.797-145.606c43.886-36.825,99.482-54.363,156.556-49.361
                              c57.071,4.992,108.78,31.911,145.605,75.797c61.961,73.843,67.147,178.148,13.951,257.244L403.69,347.14l3.527,89.071
                              l89.061-3.492l-31.232-28.867C496.545,359.331,513.165,305.001,511.936,250.128z"
                              />

                              <path
                                d="M401.299,254.823c-0.001-39.527-15.687-76.399-44.171-103.82c-28.471-27.411-65.938-41.682-105.527-40.175
                              c-37.302,1.416-72.187,17.133-98.225,44.251c-26.023,27.104-40.33,62.591-40.284,99.926c0.047,37.735,14.579,73.395,40.92,100.407
                              c9.115,9.347,15.431,20.644,18.54,32.84h-18.344v20.756c0,56.787,46.199,102.988,102.987,102.988s102.988-46.201,102.988-102.988
                              v-20.756h-18.341c3.112-12.199,9.437-23.502,18.56-32.865C386.776,328.327,401.299,292.614,401.299,254.823z M257.194,470.487
                              c-26.617,0-49.338-17.004-57.871-40.721h115.742C306.533,453.482,283.811,470.487,257.194,470.487z M330.673,326.418
                              c-16.791,17.229-27.55,38.727-31.186,61.837h-22.723V283.686h-41.511v104.568h-20.35c-3.634-23.114-14.386-44.605-31.171-61.82
                              c-18.751-19.231-29.097-44.616-29.13-71.48c-0.066-54.532,44.153-100.579,98.574-102.645c28.219-1.072,54.893,9.084,75.162,28.599
                              c20.28,19.524,31.449,45.774,31.449,73.916C359.786,281.728,349.447,307.154,330.673,326.418z"
                              />
                            </svg>
                          </div>
                        </div>

                        <span className="   text-gray-900 col-span-3 mt-10">
                          Ochrana domácnosti proti BlackOutu
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-12 bg-white">
            <div className="max-w-6xl mx-auto grid lg:grid-cols-2  px-8">
              <div>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Řešení od 7 MWh
                </p>
                <p className="mt-4 max-w-2xl  text-gray-500  ">
                  Nejmenší solární fotovoltaické soupravy pro domácnosti jsou
                  cenově nejvýhodnější a nejjednodušší řešení pro domácí použití
                  s nižší mírou soběstačnosti. Jsou ideální pro všechny
                  domácnosti, které chtějí snížit náklady na energii pomocí
                  účinného a čistého energetického systému.
                </p>
              </div>
              <div className="p-10 text-center">
                <StaticImage
                  src="../images/somi.png"
                  className=" w-48  "
                  alt="Malgen"
                />
              </div>
            </div>

            <div className="max-w-6xl mx-auto grid lg:grid-cols-2  rounded-md  p-8 mt-6">
              <div>
                <p className="mt-2  leading-8 font-bold text-xl  text-gray-900 ">
                  Obsah našeho hybridního systému pro domácnosti
                </p>
                <div className="prose ml-5">
                  <ol>
                    <li>Solární panely</li>
                    <li>Hybridní střídač</li>
                    <li>Unikátní úchytný systém</li>
                    <li>Baterie</li>
                    <li>Hlavní vypínač</li>
                    <li>FV, bateriový a uzemňující kabeláž</li>
                    <li>Nářadí pro kabeláž</li>
                    <li>Wall Box</li>
                  </ol>
                </div>
              </div>
              <div className="p-10 text-center">
                <StaticImage
                  src="../images/somi.jpg"
                  className=" rounded-md "
                  alt="Malgen"
                />
              </div>
            </div>
          </div>
          <div className="  col-span-2  text-center py-10 bg-gradient-to-r from-sky-600 to-sky-900">
            <div className="grid lg:grid-cols-2  gap-6  max-w-6xl mx-auto  ">
              <div className="text-white text-3xl  ">
                Neváhejte a kontaktujte nás
              </div>
              <div className="mx-auto">
                <Button />
              </div>
            </div>
          </div>

          <div className=" pt-12 sm:pt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto text-center  ">
                <h2 className="text-2xl inset-0 sm:text-4xl">
                  <strong>Spolehlivé</strong> a ověřené
                </h2>
              </div>
            </div>
            <div className="mt-10 pb-12   sm:pb-16">
              <div className="relative">
                <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-6xl mx-auto ">
                    <div className="">
                      <div className="  grid gap-4 p-10 max-w-lg mx-auto rounded-lg bg-white shadow-md overflow-hidden">
                        <div className="relative">
                          <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                          <div className="grid grid-cols-4 relative">
                            <div>
                              <div className="bg-lime-600 rounded-full p-3 inline-block ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-8 h-8 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                            </div>

                            <span className="   text-gray-900 col-span-3 font-bold text-lg">
                              Čerpání maximální dotace
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                          <div className="grid grid-cols-4 relative">
                            <div>
                              <div className="bg-lime-600 rounded-full p-3 inline-block ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-8 h-8 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                            </div>

                            <span className="   text-gray-900 col-span-3 font-bold text-lg">
                              Zajištění peněz proti inflaci
                            </span>
                          </div>
                        </div>

                        <div className="relative">
                          <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                          <div className="grid grid-cols-4 relative">
                            <div>
                              <div className="bg-lime-600 rounded-full p-3 inline-block ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-8 h-8 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                            </div>

                            <span className="   text-gray-900 col-span-3 font-bold text-lg">
                              Vyřízení dotace Nová Zelená úsporám
                            </span>
                          </div>
                        </div>

                        <div className="relative">
                          <div className="border-t-2 absolute   top-7 left-16 right-4"></div>
                          <div className="grid grid-cols-4 relative">
                            <div>
                              <div className="bg-lime-600 rounded-full p-3 inline-block ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-8 h-8 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className=" col-span-3">
                              <span className="   text-gray-900 font-bold text-lg">
                                Realizace od 275 000 Kč
                              </span>
                              <div className="   text-gray-900 col-span-3  ">
                                dostupná nabídka díky využití dotace
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-12 bg-white">
            <div className="max-w-6xl mx-auto grid lg:grid-cols-2 gap-4 px-8">
              <div>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Fotovoltaika SOMI
                </p>
                <div className="my-6 prose">
                  <p>
                    Hybridní systém SOMI fotovoltaické elektrárny kombinuje
                    technologii on-grid i off-grid, což znamená systém
                    fotovoltaické elektrárny, který může být zapojený v sítí a
                    zároveň dokáže fungovat jako samostatný ostrovní systém.
                    Systém fotovoltaických elektráren obsahuje fotovoltaické
                    panely, střídače, bateriové úložiště a montážní sadu.
                    Fotovoltaické panely jsou na bázi nejmodernější technologie
                    monokrystalických Half-Cut článků s maximálním výkonem 550
                    Wattu. S minimální životností 30 let.{" "}
                  </p>

                  <p>
                    Bateriové úložiště představuje třetí generaci
                    Lithium-Železo-Folsfátové banky, která především zvyšuje
                    účinnost, zabraňuje vznícení baterie a s minimální
                    životností 6000 cyklů. Nejdůležitější součástí jsou střídače
                    SOMI, které obsahují patentový systém SOMI Systém, který
                    optimalizuje, ukládá a řídí toky energie z fotovoltaických
                    panelů a sítě do baterie nebo z baterie zpět do sítě. Naše
                    hybridní systémy umožňují uživateli maximální komfort, kdy
                    si podle svých vlastních preferencí může nastavit systém dl
                    osobních potřeb, tedy například zda upřednostňuje svoji
                    spotřebu, či maximální nabití baterie, anebo minimalizaci
                    spotřeby energie ze sítě.
                  </p>
                </div>
              </div>
              <div>
                <div className="lg:m-10 bg-sky-100 shadow-md rounded-md p-10">
                  <div className="text-xl my-3 font-bold">
                    Digitální rozhraní
                  </div>
                  <div className="">
                    V našem jednoduchém uživatelském rozhraní si stačí nastavit
                    své preference. Ať už je to maximalizace zisku, nabití
                    baterie nebo pokrytí, co nejvíce vlastní spotřeby z
                    obnovitelného zdroje, to vše lze nastavit skrze aplikaci.
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <svg
                      version="1.1"
                      className="w-16 h-16 text-sky-600 mx-auto mt-6"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 473.181 473.181"
                    >
                      <path
                        d="M73.024,352.952h327.14c19.364,0,35.122-15.749,35.122-35.121V95.817
		c0-19.372-15.758-35.121-35.122-35.121H73.024c-19.364,0-35.122,15.749-35.122,35.121v222.014
		C37.903,337.203,53.661,352.952,73.024,352.952z M70.45,95.817c0-1.414,1.152-2.574,2.574-2.574h327.14
		c1.422,0,2.574,1.16,2.574,2.574v222.014c0,1.414-1.152,2.574-2.574,2.574H73.024c-1.422,0-2.574-1.16-2.574-2.574V95.817z"
                      />
                      <path
                        d="M460.411,366.271H12.77c-7.056,0-12.77,5.72-12.77,12.776c0,18.467,14.97,33.438,33.445,33.438
		h406.289c18.476,0,33.446-14.971,33.446-33.438C473.181,371.99,467.467,366.271,460.411,366.271z"
                      />
                      <path
                        d="M212.358,241.979c0.581,1.683,2.083,2.875,3.855,3.065c1.764,0.176,3.48-0.667,4.402-2.192
		l10.83-17.879l33.611,33.613c0.859,0.857,2.026,1.334,3.242,1.334c1.216,0,2.384-0.477,3.244-1.334l16.797-16.814
		c1.789-1.797,1.789-4.689,0-6.484l-33.605-33.596l17.896-10.838c1.517-0.923,2.376-2.64,2.184-4.404
		c-0.182-1.778-1.374-3.271-3.051-3.861l-82.154-28.605c-1.668-0.571-3.512-0.158-4.752,1.097c-1.248,1.24-1.668,3.084-1.097,4.752
		L212.358,241.979z"
                      />
                    </svg>

                    <div className="space-y-4 sm:space-y-0  mt-8 ">
                      <a
                        href="https://www.somias.cz/"
                        target="_blank"
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 bg-opacity-80 hover:bg-opacity-60 sm:px-8"
                      >
                        Další informace na somias.cz
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-12  ">
            <div className="max-w-6xl mx-auto grid lg:grid-cols-2  px-8">
              <div className="p-8">
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Proč si vybrat SOMI?{" "}
                </p>
                <div className="my-6 prose">
                  <ul>
                    <li>Součástí ceny je systém inteligentního řízení</li>
                    <li>Doživotní záruka na fotovoltaické panely</li>
                    <li>
                      Optimalizované jednotlivé komponenty k minimální spotřebě
                      energie a maximální účinnosti.
                    </li>
                    <li>SOMI Vám zařídí kompletní financování systému</li>
                  </ul>
                </div>
              </div>
              <div>
                <StaticImage
                  src="../images/panely-strecha.jpg"
                  className="h-full object-cover border border-gray-200 "
                  alt="Malgen"
                />
              </div>
            </div>

            <div className="max-w-6xl mx-auto grid lg:grid-cols-2  px-8">
              <div className="order-2 lg:order-1">
                <StaticImage
                  src="../images/panely-na-strese.jpg"
                  className="h-full  object-cover border border-gray-200  "
                  alt="Malgen"
                />
              </div>
              <div className="order-1 lg:order-2 p-8">
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Výhody systému SOMI{" "}
                </p>
                <div className="my-6 prose">
                  <ul>
                    <li>Unikátní patentové technické řešení</li>
                    <li>
                      Systém s umělou inteligencí optimalizuje průběžně svoji
                      činnost z historických dat
                    </li>
                    <li>
                      Reaguje na momentální situaci fotovoltaické elektrárny
                    </li>
                    <li>
                      Reaguje na celkovou situaci sítě, na kterou je připojeno{" "}
                    </li>
                    <li>
                      Dává inteligentně pokyny podle předem nastavených
                      požadavků uživatele
                    </li>
                    <li>
                      Řídí distribuci energie mezi fotovoltaickou elektrárnou a
                      sítí
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="  col-span-2  text-center py-10 bg-gradient-to-r from-sky-600 to-sky-900">
            <div className="grid  gap-6  max-w-6xl mx-auto  ">
              <div className="text-white text-3xl px-5 ">
                Začněte si také vyrábět svou vlastní elektřinu ze sluneční
                energie
              </div>
              <div className="mx-auto">
                <Button text={"Vyplňte žádost o nabídku"} />
              </div>
            </div>
          </div>
          <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                  Kávu platíme my
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Pomáháme firmám i jednotlivcům
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                  zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                  zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
                </p>
              </div>

              <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-xl  font-medium text-gray-900">
                    800 991 100
                  </p>
                </div>
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <MailIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <a
                    href="mailto:office@malgen.cz"
                    className="ml-3 text-xl  font-medium text-gray-900"
                  >
                    office@malgen.cz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default IndexPage
